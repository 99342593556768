import logo from "./logo.svg";
import close from "./close.svg";
import message from "./message.svg";
import alert from "./alert.svg";
import linkedin from "./linkedin.svg";
import styled from "styled-components";
import IconBullet from "../IconBullet/IconBullet";

const Span = styled.span`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: row;
`;

function HeaderLogo() {
  return (
    <Span>
      <IconBullet src={close} alt="close" />
      <IconBullet src={alert} alt="alert" />
      <IconBullet src={message} alt="message" />
      <a
        href="https://www.linkedin.com/in/yonatanhanan/"
        target="_blank"
        rel="noreferrer">
        <img src={linkedin} alt="linkedin" height="30px" />
      </a>
      <img src={logo} alt="logo" height="30px" />
    </Span>
  );
}

export default HeaderLogo;
