import Editor from "@monaco-editor/react";

const text = `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Yonatans Bio</title>
  </head>
  <body>
    Hello, I'm Yonatan AKA Joe.
    I am 25 years old and married to Yonit + 1.

    From a young age I loved software in all its forms.
    Currently focusing on Fullstack development.
    I am creative and like to solve problems and challenges.
  </body>
</html>
`;

function Description() {
  return (
    <Editor
      height="400px"
      defaultLanguage="html"
      defaultValue={text}
      options={{ readOnly: true, fontSize: 20 }}
    />
  );
}

export default Description;
