import styled from "styled-components";
import { randomNumber } from "../../utils";

const Span = styled.span`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 2px 4px;
  flex-direction: row;
  border: #cacdd1 solid 1px;
  border-radius: 2px;
  margin-right: 3px;

  &:hover {
    background-color: #eaeaea;
    color: #202124;
  }
`;

const Image = styled.img`
  width: 15px;
  padding: 3px;
  margin-right: 2px;
`;

type Props = {
  src: string;
  alt: string;
};

function IconBullet(props: Props) {
  return (
    <Span>
      <Image src={props.src} alt={props.alt} />
      {randomNumber(1, 50)}
    </Span>
  );
}

export default IconBullet;
