import styled from "styled-components";

type Props = {
  name: string;
  value: string;
};

const KeyLabel = styled.span`
  color: #cb0000;
  font-family: "Source Code Pro", monospace;
`;

const KeyValue = styled.span`
  color: black;
  font-family: "Source Code Pro", monospace;
`;

function Row(props: Props) {
  return (
    <div>
      <KeyLabel>{props.name}: </KeyLabel>
      <KeyValue>{props.value};</KeyValue>
    </div>
  );
}

export default Row;
