import styled from "styled-components";
import Me from "./me.jpg";
import Row from "./Row";

const Photo = styled.img`
  width: 90px;
  height: 110px;
  border-radius: 50%;
  align-self: center;
`;

const Section = styled.div`
  display: flex;
  min-width: 200px;
  flex-direction: column;
  padding: 15px;
`;

const Wrapper = styled.div`
  border: 1px solid #cacdd1;
  border-top: none;
  max-width: max-content;
`;

const Title = styled.div`
  font-size: 15px;
  border-bottom: 1px solid #cacdd1;
  background-color: #f1f3f4;
  color: #202124;
  padding: 5px;
`;

function Features() {
  return (
    <Wrapper>
      <Title>Features</Title>
      <Section>
        <Photo src={Me} />
        <Row name="height" value="164cm" />
        <Row name="weight" value="unset" />
        <Row name="children" value="1" />
        <Row name="border" value="israel" />
        <Row name="color" value="#E9B09F" />
        <Row name="direction" value="ltr" />
      </Section>
    </Wrapper>
  );
}

export default Features;
