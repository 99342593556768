import cursor from "./cursor.svg";
import screens from "./screens.svg";
import styled from "styled-components";

const Span = styled.span`
  max-width: 85px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 5px;
  flex-direction: row;
`;

const Icon = styled.img`
  height: 30px;
`;

const Seperator = styled.span`
  height: 20px;
  border-right: #bebcbcb3 solid;
  border-width: 2px;
`;

function HeaderIcons() {
  return (
    <Span>
      <Icon src={cursor} alt="cursor" />
      <Icon src={screens} alt="screens" />
      <Seperator />
    </Span>
  );
}

export default HeaderIcons;
