import styled from "styled-components";
import Description from "./Description/Description";
import Features from "./Features/Features";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: max-content;
`;

function About() {
  return (
    <Wrapper>
      <Description />
      <Features />
    </Wrapper>
  );
}

export default About;
