import HeaderIcons from "../HeaderIcons/HeaderIcons";
import Section from "../Section/Section";
import styled from "styled-components";
import HeaderLogo from "../HeaderLogo/HeaderLogo";

const sections = ["About", "Jobs", "Projects", "Education"];

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 85px repeat(${sections.length}, max-content) 1fr auto;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #f1f3f4;
  border: 1px solid #cacdd1;
`;

function Header() {
  return (
    <header>
      <Nav>
        <HeaderIcons />
        {sections.map((section, i) => (
          <Section title={section} key={i} />
        ))}
        <span></span>
        <HeaderLogo />
      </Nav>
    </header>
  );
}

export default Header;
